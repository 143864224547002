import "core-js/modules/es.array.push.js";
import { defineComponent, toRefs, reactive, computed, watch, nextTick, defineAsyncComponent } from "vue";
import { useStore } from "vuex";
import { useMessage } from "@/hooks/web/useMessage";
import API from "@/api/authority";
import { sleep } from "@/utils/sleep";
export default defineComponent({
  props: {
    visible: {
      type: Boolean
    },
    orgId: {
      type: Number
    }
  },
  components: {
    Eldialog: defineAsyncComponent(() => import("@/components/Eldialog/index.vue"))
  },
  emits: ["update:visible", "handleSuccess"],
  setup(props, {
    emit
  }) {
    const {
      state
    } = useStore();
    const message = useMessage();
    const userInfo = computed(() => state.userInfo);
    const refData = reactive({
      defaultList: [],
      isAdd: false,
      activeRoleId: null,
      type: [],
      roleId: null,
      roleName: "",
      roleDesc: "",
      treeRef: null,
      resourceIdList: [],
      props: {
        label: "name",
        children: "children"
      },
      permissionList: [],
      total: []
    });
    const show = computed({
      get: () => props.visible,
      set: val => {
        emit("update:visible", val);
      }
    });
    watch(() => refData.activeRoleId, async val => {
      const {
        roleName,
        roleDesc
      } = refData.type.find(item => item.value === val);
      handleSwitckRole();
      await sleep(200);
      refData.roleName = roleName;
      refData.roleDesc = roleDesc;
    });
    // 监听弹框
    watch(() => props.visible, val => {
      if (!refData.type.length && !refData.isAdd) {
        message.warning('请选择角色');
        emit("update:visible", false);
        return false;
      }
      nextTick(async () => {
        if (val) {
          getRoleList(props.orgId);
          await sleep(200);
          handleCheck();
        } else {
          refData.treeRef.setCheckedNodes([11]);
        }
      });
    });
    //全部选项
    const TreeResource = computed(() => {
      let arr = [];
      // 轮询全部资料
      state.TreeResource.map((item, index) => {
        const {
          children,
          ...params
        } = item;
        const json = params;
        // 轮询判断是否存在子集
        if (item.hasOwnProperty("children")) {
          json.children = [];
          item.children.map(val => {
            if (val.hasOwnProperty("children")) {
              val.children = val.children.map(key => {
                return {
                  id: key.id,
                  name: key.name,
                  disabled: !refData.isAdd
                };
              });
            }
            //判断当前组织下是否有该权限,存在则显示
            refData.permissionList.includes(val.id) && json.children.push({
              ...val,
              disabled: !refData.isAdd
            });
          });
        }
        //判断当前组织下是否有该权限,存在则显示
        refData.permissionList.includes(item.id) && arr.push({
          ...json,
          disabled: !index ? true : !refData.isAdd
        });
      });
      return [{
        id: 1,
        name: refData.roleName || "账号权限",
        children: arr,
        disabled: false
      }];
    });
    //获取当前勾选项
    const getCheckedNodes = () => {
      refData.resourceIdList = [11];
      const params = refData.treeRef.getCheckedNodes(false, true);
      params.map(item => {
        !refData.resourceIdList.includes(item.parentId) && item.parentId && item.parentId != -1 && refData.resourceIdList.push(item.parentId);
        refData.resourceIdList.push(item.id);
      });
      return refData.resourceIdList;
    };
    //保存
    const confirm = async () => {
      try {
        // 判断当前是否为自定义
        if (refData.isAdd) {
          if (!refData.roleName) throw {
            msg: "请填写角色名称"
          };
          if (refData.roleName.length > 20) throw {
            msg: "角色名称长度在 1 到 20 个字符"
          };
          if (refData.roleDesc.length > 20) throw {
            msg: "备注长度在 1 到 20 个字符"
          };
          if (!getCheckedNodes().length) throw {
            msg: "请选择权限"
          };
          const params = {
            id: refData.roleId,
            name: refData.roleName,
            code: '',
            dataScope: 4,
            dataScopeDeptIds: [],
            remark: refData.roleDesc,
            resourceIdList: getCheckedNodes()
          };
          const {
            msg,
            code
          } = await API.addRole(params);
          message[code === 0 ? "success" : "warning"](code === 0 ? "新增成功" : msg);
          if (code === 0) {
            emit("update:visible", false);
            emit("handleSuccess");
          }
        } else {
          emit("update:visible", false);
        }
      } catch ({
        msg
      }) {
        message.warning(msg);
      }
    };
    //获取当前权限选项
    const getRoleList = async orgId => {
      const {
        data,
        msg,
        code
      } = await API.listResource({
        deptId: orgId
      });
      refData.permissionList = code === 0 ? data.map(item => item.resourceId) : [];
    };
    //查看当前权限选项
    const handleCheck = async () => {
      if (refData.isAdd) {
        refData.defaultList = [11];
        await sleep(100);
        refData.roleName = '';
        refData.roleDesc = '';
      } else {
        refData.activeRoleId = refData.type[0].value;
        const {
          data,
          msg,
          code
        } = await API.listResourceByRoleId({
          ids: refData.type.map(item => item.value),
          orgId: props.orgId
        });
        if (code === 0) {
          refData.total = data;
          handleSwitckRole();
        } else {
          message.warning(msg);
        }
      }
    };
    // 切换查看角色
    const handleSwitckRole = () => {
      var _refData$total$find, _refData$defaultList, _refData$defaultList2;
      refData.defaultList = (_refData$total$find = refData.total.find(item => item.id === refData.activeRoleId)) === null || _refData$total$find === void 0 ? void 0 : _refData$total$find.resource.map(item => item.resourceId);
      !((_refData$defaultList = refData.defaultList) !== null && _refData$defaultList !== void 0 && _refData$defaultList.some(item => item === 11)) && ((_refData$defaultList2 = refData.defaultList) === null || _refData$defaultList2 === void 0 ? void 0 : _refData$defaultList2.push(11));
    };
    const handleCheckChange = (data, checked, deep) => {};
    return {
      ...toRefs(refData),
      TreeResource,
      getCheckedNodes,
      show,
      confirm,
      handleCheckChange,
      getRoleList
    };
  }
});